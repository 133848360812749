<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        {{ $t("AUTH.LOGIN.NOT_HAVE_ACCOUNT") }}
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'register' }"
      >
        {{ $t("AUTH.REGISTER.TITLE") }}
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <img :src="logo" class="max-w-400px align-center" alt="" />
        <br /><br /><br /><br />
        <h3 class="font-size-h1">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t("AUTH.LOGIN.TITLE") }}
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmitData">
        <div
          role="alert"
          v-bind:class="[errors === '' ? '' : 'alert alert-danger']"
        >
          <div class="alert-text">
            {{ $t(errors) }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            placeholder="User Name"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t("AUTH.VALIDATION.INVALID", { name: "User Name" }) }}.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            placeholder="Password"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            {{ $t("AUTH.VALIDATION.REQUIRED", { name: "Password" }) }}.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <router-link
              class="text-dark-60 text-hover-primary my-3 mr-2"
              :to="{ name: 'forgotPassword' }"
          >
            {{ $t("AUTH.FORGOT.TITLE") }}
          </router-link>
<!--          <a-->
<!--            href="#"-->
<!--            class="text-dark-60 text-hover-primary my-3 mr-2"-->
<!--            id="kt_login_forgot"-->
<!--          >-->
<!--            {{ $t("AUTH.FORGOT.TITLE") }}-->
<!--          </a>-->
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
            v-bind:class="[
              loading ? 'spinner spinner-light spinner-right' : ''
            ]"
          >
            {{ $t("AUTH.LOGIN.BUTTON") }}
          </button>
          <button
            class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
            @click="cancelForm"
          >
            Cancel
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {
  LOGIN, LOGOUT,
  SET_ERROR,
  SET_LOADING_LOGIN,
  // SET_USER_INFO,
  SET_SUCCESS_ACTION
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { VERIFY_AUTH } from "../../../core/services/store/auth.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  isLoading: false,
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      loading: false,
      successAction : false,
      errors: "",
      logo: process.env.BASE_URL + "media/logos/komnasham.png"
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted() {
    this.subscribes();
    if (this.isAuthenticated) this.$router.push("dashboard");
    // if (this.isAuthenticated) this.$store.dispatch(LOGOUT);
  },
  methods: {
    subscribes() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_LOADING_LOGIN) {
          this.loading = this.getLoadingInfo;
        }
        if (mutation.type === SET_SUCCESS_ACTION) {
          this.successAction = this.getSuccessStatus;
        }
        if (mutation.type === SET_ERROR) {
          this.errors = this.getError;
        }
        // if (mutation.type === SET_USER_INFO) {
        //   this.$router.push("dashboard");
        // }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cancelForm() {
      this.$router.push("dashboard");
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmitData() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loading = this.getLoadingInfo;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      this.$store.dispatch(LOGIN, { email, password })
        .then(() => {
          this.$store.dispatch(VERIFY_AUTH)
            .then(() => {
              const uInfo = JSON.parse(localStorage.getItem("user_info"));
              let date_begin = new Date('08/01/2022');
              let date_default = new Date('09/01/2022');
              if(uInfo.data[0].password_date != null) {
                // var dateParts = uInfo.data[0].password_date.split("-");
                date_default = new Date(uInfo.data[0].password_date);
              }
              let difference = date_default.getTime() - date_begin.getTime();
              let totalDays = Math. ceil(difference / (1000 * 3600 * 24));
              let limit = process.env.VUE_APP_LIMIT_PASSWORD;
              this.$router.push("survey");
              if(totalDays >= limit){
                Swal.fire({
                  title: this.$t("AUTH.CHANGE_PASSWORD.TITLE_ASK"),
                  text: this.$t("AUTH.CHANGE_PASSWORD.TITLE_MSG"),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this.$t("AUTH.CHANGE_PASSWORD.CONFIRM_BTN"),
                  cancelButtonText: this.$t("AUTH.CHANGE_PASSWORD.CANCEL_BTN")
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push("changePassword");
                  }
                })
              }
            })
            .catch(() => {
              this.$store.dispatch(LOGOUT)
            });
        })
        .catch(() => {
          this.$store.dispatch(LOGOUT)
        });;
    }
  },
  computed: mapGetters(["getLoadingInfo", "getError", "isAuthenticated"])
};
</script>
